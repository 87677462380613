import { PropsWithChildren } from "react";
import { isNotNullAndUndefined } from "../helpers/utils";
import {
  GetFieldsDisplayName,
  GetSelectedField,
  GetSelectedFieldDescription,
  GetSelectedFieldDescriptionStyle,
} from "../helpers/AppHelper";
import { RAFAttributesContext } from "../Providers/RAFAttributeRelatedListProvider";
import "./InputStyle.scss";
import { RAFDefaultFieldProps, RAFFieldProps } from "./RFFUtils";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

//const RAFTextBox = ({ field, label, required }) => {
interface IProps {
  count?: number;
}
function RAFFieldLabel<T>({
  field,
  label,
  required,
  description,
  labelClassName,
  ...props
}: PropsWithChildren<IProps & RAFFieldProps<T>>) {
  return (
    <RAFAttributesContext.Consumer>
      {({ queryAttributes, attributeRelatedList }) => {
        let selectedFieldDescription = isNotNullAndUndefined(description)
          ? description
          : GetSelectedFieldDescription(
              queryAttributes,
              field.toString(),
              attributeRelatedList
            );

        let showDescriptionAsLable = GetSelectedFieldDescriptionStyle(
          queryAttributes,
          field.toString(),
          attributeRelatedList
        );
        return (
          <div
            className={`${
              isNotNullAndUndefined(labelClassName) && labelClassName !== ""
                ? labelClassName
                : "col-12"
            }`}
          >
            <div className="row flex-column g-0 form-label-container">
              <div className="col d-flex">
                {showDescriptionAsLable === true &&
                isNotNullAndUndefined(selectedFieldDescription) ? (
                  <label
                    className={required ? "form-label required" : "form-label"}
                  >
                    {selectedFieldDescription}
                  </label>
                ) : showDescriptionAsLable === false &&
                  isNotNullAndUndefined(selectedFieldDescription) ? (
                  <div className="d-flex align-items-center">
                    <label
                      className={
                        required ? "form-label required" : "form-label"
                      }
                    >
                      {GetFieldsDisplayName(
                        queryAttributes,
                        field.toString(),
                        label,
                        attributeRelatedList
                      )}{" "}
                    </label>
                    <div className="ms-2">
                      <TooltipComponent
                        content={selectedFieldDescription}
                        position="TopCenter"
                      >
                        <span className="fa fa-info-circle"></span>
                      </TooltipComponent>
                    </div>
                  </div>
                ) : (
                  <label
                    className={required ? "form-label required" : "form-label"}
                  >
                    {GetFieldsDisplayName(
                      queryAttributes,
                      field.toString(),
                      label,
                      attributeRelatedList
                    )}{" "}
                  </label>
                )}

                {isNotNullAndUndefined(props.count) && props.count > 0 && (
                  <span className="ms-1 e-badge e-badge-primary e-badge-circle">
                    {props.count}
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </RAFAttributesContext.Consumer>
  );
}

RAFFieldLabel.defaultProps = RAFDefaultFieldProps;

export default RAFFieldLabel;

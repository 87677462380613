import * as React from 'react';
import { PropsWithChildren } from 'react';
import "./InputStyle.scss";
import { RAFChoiceOptionProps } from './RFFUtils';


function RAFChoiceOption(
    { 
        label, 
        value, 
        category, 
        children,
      //  IconCss 
    }: PropsWithChildren<RAFChoiceOptionProps>,
) {
    return (
        <div>
        </div>
    )
}

//RAFChoiceOption.defaultProps = RAFDefaultFieldProps;

export default RAFChoiceOption;
import arrayMutators from "final-form-arrays";
import * as React from "react";
import { PropsWithChildren } from "react";
import { Field, Form, FormSpy, useField } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { createInstance } from "../../RAFComponents/Utility/FormUtility";
import { ContentType, RAFLayout } from "../../RAFComponents/helpers/Constants";
import {
    Guid,
    getSaveRequest,
    isNotEmptyArray,
    isNotNullAndUndefined,
    isNullOrUndefined
} from "../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../RAFComponents/models/Common/RAFEntityBase";
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import { showErrorToast, showSuccessToast, showWarningToast } from "../Utility/RAFToastComponent";
import { hideProgress, showProgress } from "../helpers/AppHelper";
import "./InputStyle.scss";
import { RAFFormContext, RAFFormProps } from "./RFFUtils";


export const RAFLayoutContext = React.createContext(RAFLayout.OneColumnLayout);

export const WhenFieldChanges = ({ field, set, to }) => (
    <Field name={set} subscription={{}}>
        {(
            // No subscription. We only use Field to get to the change function
            { input: { onChange } }
        ) => (
            <FormSpy subscription={{}}>
                {({ form }) => (
                    <OnChange name={field}>
                        {(value) => {
                            onChange(to);
                        }}
                    </OnChange>
                )}
            </FormSpy>
        )}
    </Field>
);

export const WhenFieldChangesTo = ({ field, becomes, set, to }) => (
    <Field name={set} subscription={{}}>
        {(
            // No subscription. We only use Field to get to the change function
            { input: { onChange } }
        ) => (
            <FormSpy subscription={{}}>
                {({ form }) => (
                    <OnChange name={field}>
                        {(value) => {
                            if (value === becomes) {
                                onChange(to);
                            }
                        }}
                    </OnChange>
                )}
            </FormSpy>
        )}
    </Field>
);

// value:string, is:[]
export const ConditionIncludes = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) =>
            (is.includes(value) ? children : null)
        }
    </Field>
);

//value: [],is:string
export const ConditionItemExists = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => {
            return (isNotNullAndUndefined(value) && isNotNullAndUndefined(is) && value.includes(is) ? children : null);
        }}
    </Field>
);

// value:string, is:[]
export const ConditionNotIncludes = ({ when, isNot, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (!isNot.includes(value) ? children : null)}
    </Field>
);

export const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

export const ConditionNot = ({ when, isNot, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value !== isNot ? children : null)}
    </Field>
);

export const ConditionNotNullOrWhiteSpace = ({ when, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) =>
            value !== null &&
                value !== undefined &&
                value !== "undefined" &&
                value !== "" &&
                value !== " "
                ? children
                : null
        }
    </Field>
);

export const ConditionIsNotEmptyArray = ({ when, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (isNotEmptyArray(value) ? children : null)}
    </Field>
);

export const ConditionIsEmptyArray = ({ when, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) =>
        (!isNotEmptyArray(value) ? children : null)
             }
    </Field>
);

export const ConditionNullOrWhiteSpace = ({ when, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) =>
            value === null ||
                value === undefined ||
                value === "undefined" ||
                value === "" ||
                value === " "
                ? children
                : null
        }
    </Field>
);

export const RAFFieldError = ({ name }) => {
    const {
        meta: { touched, error },
    } = useField(name, { subscription: { touched: true, error: true } });
    return touched && error ? (
        <span className="fieldErrorMsg" id={`fieldError${name}`}>
            {error === "Required" ? "This field is required." : error}
        </span>
    ) : null;
};

export const composeValidators =
    (...validators) =>
        (value, allValues, fieldState) => {
            validators = validators.filter(function (e) {
                return e !== null;
            });
            return validators.reduce(
                (error, validator) => error || validator(value, allValues, fieldState),
                undefined
            );
        };

/*export const validateFormTypeByAdditionalInfo = (value, allValues, fieldState) => {
    if (isNotNullAndUndefined(allValues) && allValues["AdditionalInfo"] === true && IsNullOrWhiteSpace(value)) {
        return ('This field is required.');
    }
    return (undefined);
}*/

//const RAFForm = ({ initialValues, children, formRef, layout, onSubmit }) => {
function RAFForm<T>({
  initialValues,
  formRef,
  layout = RAFLayout.OneColumnLayout,
  onSubmit,
  type,
  primaryKey,
  onSave,
  decorators,
  submitOnEnterKey = true,
  convertBeforeSave,
  progressTarget,
  children,
  className,
  ...props
}: PropsWithChildren<RAFFormProps<T>>) {
    const formId = "formDiv_" + Guid.newGuid();
    //const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    const SubmitAndSave = (values) => {
        const progressDiv = showProgress(
            progressTarget ? progressTarget : "#" + formId
        );
        let objValues = null;
        if (isNotNullAndUndefined(convertBeforeSave)) {
            objValues = convertBeforeSave(values);
        } else {
            objValues = values;
        }
        //await sleep(1000)
        //console.log(JSON.stringify(values), objValues)
        //return;
        if (isNotNullAndUndefined(type)) {
            let objRAFEntity = createInstance(type) as RAFEntityBase;
            const url = objRAFEntity.getSaveUrl();
            if (isNotNullAndUndefined(url)) {
                repositoryActions
                    .postDataAndGetResponse(
                        url,
                        getSaveRequest(objValues, primaryKey),
                        null,
                        ContentType.applicationJson
                    )
                    .then((response) => {
                        hideProgress(progressDiv);
                        if (
                            isNotNullAndUndefined(response) &&
                            isNotNullAndUndefined(response.data)
                        ) {
                            if (
                                response.data.EntityId !== null &&
                                response.data.EntityId !== undefined &&
                                response.data.EntityId !== ""
                            ) {
                                //console.log('1', response.data.EntityId);
                                showSuccessToast("Saved successfully");
                                if (onSave) {
                                    onSave(response.data.EntityId, response.data.ObjectName);
                                }
                            } else if (
                                response.data.Error !== null &&
                                response.data.Error !== undefined &&
                                response.data.Error !== ""
                            ) {
                                showWarningToast("Sorry something went wrong !");
                                //console.log("Error on save", response.data.Error);
                            }
                        }
                    })
                    .catch((error) => error);
            } else {
                hideProgress(progressDiv);
                showWarningToast("Sorry something went wrong !");
            }
        } else {
            hideProgress(progressDiv);
            showWarningToast("Sorry something went wrong !");
        }
    };

    const preventEnterKeySubmission = (e: React.KeyboardEvent) => {
        const target = e.target as HTMLInputElement | HTMLTextAreaElement;
        if (e.key === "Enter") {
            const objElement: HTMLElement = document.getElementById(target.id);
            //!["DIV"].includes(target.tagName)
            if (
                !["TEXTAREA"].includes(target.tagName) &&
                isNullOrUndefined(objElement.closest(".raf_prevent_submit"))
            ) {
                if (submitOnEnterKey === false) {
                    e.preventDefault();
                }
            }
        }
    };

    return (
        <RAFLayoutContext.Provider value={layout}>
            <Form
                onSubmit={onSubmit || SubmitAndSave}
                decorators={decorators}
                initialValues={initialValues}
                mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                    ...arrayMutators,
                }}
                //render={({ handleSubmit, form, submitting, pristine, values }) => {
                render={(e) => {
                    if (formRef) formRef(e);
                    return (
                        <form
                            id={formId.toString()}
                            // onKeyPress={(e) => {
                            //     e.key === 'Enter' && !submitOnEnterKey && e.preventDefault();
                            // }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter' && !submitOnEnterKey) {
                                    e.preventDefault();
                                }
                            }}
                            onKeyDown={preventEnterKeySubmission}
                            // onKeyDown={(e) => {
                            //     console.log('onKeyDown');
                            //     if (e.key === 'Enter' && !submitOnEnterKey) {
                            //         console.log('Enter');
                            //         e.preventDefault();
                            //     }
                            // }}

                            className={className}
                            onSubmit={
                                //e.handleSubmit

                                (event) => {
                                    if (e.invalid) {
                                        //showErrorToast(JSON.stringify(e.errors, undefined, 2));
                                        showErrorToast("Please provide all the required fields");
                                    }
                                    e.handleSubmit(event);
                                }
                            }
                        >
                            {props.onChange && (
                                <FormSpy
                                    subscription={{ values: true }}
                                    onChange={(props1) => {
                                        props.onChange(props1.values);
                                    }}
                                />
                            )}
                            <RAFFormContext.Provider value={e}>
                                {children}
                            </RAFFormContext.Provider>
                        </form>
                    );
                }}
            />
        </RAFLayoutContext.Provider>
    );
}

//RAFForm.propTypes = {
//    initialValues: PropTypes.object,
//    formRef: PropTypes.func,
//    onSubmit: PropTypes.func,
//    layout: PropTypes.oneOf([RAFLayout.OneColumnLayout, RAFLayout.TwoColumnLayout, RAFLayout.ThreeColumnLayout, RAFLayout.FourColumnLayout]),
//};

export default RAFForm;

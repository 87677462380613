import React, { Reducer, useEffect, useReducer } from "react";
import { getMyPermissions, hasPermission, hasPermissions } from "../RAFComponents/helpers/PermissionHelper";
import { isNotNullAndUndefined } from "../RAFComponents/helpers/utils";
import { UserInfoRow } from "../RAFComponents/models/Common/UserInfoRow";
import { RoleRow } from "../RAFModules/Common/Role/RoleRow";
import { LoginUserContext } from "./RAFLoginUserContextProvider";

export const RolePermissionsContext = React.createContext({
    permissionValue: null,
    updateNewRolePermissions: null,
    hasPermission: null,
    hasPermissions: null
});

interface IState {
    loading: boolean;
    permissions: RoleRow;
}

export default function RAFRolePermissionsContextProvider({ children }) {
    const loginUserContext = React.useContext(LoginUserContext);
    const userInfoRow: UserInfoRow = isNotNullAndUndefined(loginUserContext) ? loginUserContext.userInfo : null;
    //const [permissions, setPermissions] = React.useState<RoleRow>(null);
    //const [loading, setLoading] = React.useState<boolean>(true);

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            loading: true,
            permissions: null,
        }
    );

    useEffect(() => {
        getPermissions();
    }, [userInfoRow]);

    const getPermissions = () => {
        setState({ loading: true });
        if (isNotNullAndUndefined(userInfoRow) && isNotNullAndUndefined(userInfoRow.UserUID)
            //&& isNotNullAndUndefined(userInfoRow.Tenants) 
            //&& userInfoRow.Tenants.length > 0
        ) {
            getMyPermissions().then(async permissions => {
                setState({ loading: false, permissions });
            });
        } else {
            setState({ loading: false });
        }
    };

    const updateNewRolePermissions = () => {
        if (isNotNullAndUndefined(userInfoRow) && isNotNullAndUndefined(userInfoRow.UserUID)
            //&& isNotNullAndUndefined(userInfoRow.Tenants) 
            //&& userInfoRow.Tenants.length > 0
        ) {
            getMyPermissions().then(async permissions => {
                setState({ loading: false, permissions });
            });
        }
    };

    return (
        state.loading === false &&
        <RolePermissionsContext.Provider
            value={{
                permissionValue: state.permissions,
                updateNewRolePermissions: updateNewRolePermissions,

                hasPermission: hasPermission,
                hasPermissions: hasPermissions
            }}
        >
            {children}
        </RolePermissionsContext.Provider>
    );
}
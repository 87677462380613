import React from "react";
import { IsNullOrWhiteSpace } from "./RAFComponents/helpers/utils";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    console.log('ErrorBoundary:' + JSON.stringify(error))
    // alert('ErrorBoundary:' + JSON.stringify(error));
    if (!IsNullOrWhiteSpace(error) && error.toString().includes('missing') && error.toString().includes('chunk.js')) {
      //window.location.href = '/home'
      window.location.href = '/'
    }
    else {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
import { RAFGridColumnProps, RAFDefaultGridColumnProps } from './RAFSFGridUtils';
import { PropsWithChildren } from 'react';


function RAFGridColumn<T>(
    {  }: PropsWithChildren<RAFGridColumnProps<T>>,
) {
    return null;
}
RAFGridColumn.defaultProps = RAFDefaultGridColumnProps;
export default RAFGridColumn;




